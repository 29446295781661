/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { NavLink } from 'react-router-dom';

//bs
import { Container, Row, Col } from 'reactstrap';

//images
import SignatureImg from 'css/images/r-signature.svg';
import LogoInsta from 'css/images/instagram.svg';
import LogoMail from 'css/images/mail.svg';

const Footer = () => {
	return (
		<Container>
			<Row>
				<Col className="mx-auto" md="8">
					<div className="footer-layer">
						<div className="footer-sign">
							<img src={SignatureImg} alt="Rocío González" />
						</div>
						<div className="redes">
							<a
								href="https://instagram.com/rociogonzalezevents"
								target="_blank"
								rel="noreferrer"
							>
								<img src={LogoInsta} alt="Instagram" />
							</a>

							<NavLink to="/contacto">
								<img src={LogoMail} alt="Mail" />
							</NavLink>
						</div>
						<div className="info">
							<p>© 2024 Rocío González - Todos los derechos reservados.</p>
							<p>Diseño web ~ Mateagenciacreativa</p>
						</div>
						<div className="bodas-net">
							<a
								rel="nofollow noreferrer"
								href="https://www.bodas.net/organizacion-bodas/rocio-gonzalez-events-planner--e245576"
								target="_blank"
								title="Bodas.net"
							>
								<img
									alt="Bodas.net"
									src="https://www.bodas.net/images/sellos/Sello-bodas-peq--pp245576.png"
									style={{ borderWidth: 0 }}
								/>
							</a>
						</div>
						<div id="wp-ratedWA">
							<a
								target="_blank"
								href="https://www.bodas.net/organizacion-bodas/rocio-gonzalez-events-planner--e245576"
								rel="noreferrer"
								title="Rocío González Events Planner, ganador Wedding Awards 2025 Bodas.net"
							>
								<img
									width="125"
									height="125"
									alt="Rocío González Events Planner, ganador Wedding Awards 2025 Bodas.net"
									id="wp-ratedWA-img-2025"
									src="https://cdn1.bodas.net/img/badges/2025/badge-weddingawards_es_ES.jpg"
								/>
							</a>
						</div>
						<script
							type="text/javascript"
							src="https://cdn1.bodas.net/_js/wp-rated.js?v=4"
						></script>
						<script>wpShowRatedWAv3('245576','2025');</script>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Footer;
